@font-face {
  font-family: 'Baskervville';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/baskervville@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/baskervville@latest/latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

.body{
  font-family: 'Baskerville', serif;
}


/*Testimonials*/
.test1{
  height: 100vh;
  background-image: url('./Assets/Test1.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: center center;  
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .1);
}
.test2{
  height: 100vh;
  background-image: url('./Assets/Test2.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;  
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .1);
}
.test3{
  height: 100vh;
  background-image: url('./Assets/Test3.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: center center;  
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .1);
}
.test-text{
  font-style: italic;
  color: #333;
  font-weight: 600;
}


/*Services*/
.service-cont{
  justify-content: center;
  align-items: center;
  padding: 60px;
  padding-bottom: 100px;
}
.service-name{
  font-weight: 600;
}

/*Contact Form Styling*/
.contact{
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #e2e9ee;
}
.contact-form{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #ddd;
  padding: 10px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 600px;
  width: 100%;
  color: #333;
  font-weight: 600;
}
.contact-form label {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}
form h2{
  font-size: 30px;
  text-align: center;
  padding-bottom: 10px;
}
.contact-form input,
.contact-form textarea {
  padding: 10px;
  margin-top: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  width: 100%;
  box-sizing: border-box;
}
.contact-form input:focus,
.contact-form textarea:focus {
  border-color: #8ac2be;
  outline: none;
}

.contact-form button {
  background-color: #8ac2be;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1.2rem;
  font-weight: 600;
  color: #333;
  margin-top: 10px;
}

.contact-form button:hover {
  background-color: #6a9c99;
  color: #fff;
}
.contact-section {
  text-align: center;
  font-family: 'Baskervville', serif;
  padding-bottom: 80px;
  font-weight: 600;
}
.contact-container {
  margin-top: 40px;
  text-align: center;
}

/* .contact-info {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
} */

.contact-item {
  gap: 20px;
}

.contact-item i {
  font-size: 1.5rem;
  color: #ff5722;
}

@media (min-width: 768px) {
  .contact-info {
    flex-direction: row;
    justify-content: center;
  }
}

.faq-title{
  margin-top: 50px;
  padding: 20px;
}