/*Consult us Jumbotron*/
.hero{
    display: flex;
    height: 100vh;
    background-image: url('../Assets/background.png');
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .5);
  }
.content{
    display: flex;
    flex-direction: column;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: #e2e9ee;
  }
.consult-card{
    box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, .1);
    border: 1px solid #646464;
    border-radius: 10px;
    height: auto;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
    padding: 10px;
  }
.consult-card-title {
    color: #000000;
    font-family: 'Arial, sans-serif';
    font-size: 2.0rem;
}
.consult-card-subtitle{
    color: #5c817f;
    font-size: 1.8rem;
    font-weight: 800;
}
.consult-card-body{
    font-size: 1.2rem;
}
.consult-card-button{
    background: #8ac2be;
    padding: 10px;
    font-size: 2.0rem;
    font-weight: 800;
    border: none;
}