/* About.css */

.about-container {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 1 0px;
  }
  
  .text-container {
    flex: 1 1 50%;
    padding: 20px;
    text-align: left;
  }
  
  .image-container {
    flex: 1 1 50%;
    display: flex;
    justify-content: center;
    padding: 20px;
  }
  
  .circular-image {
    width: 100%;
    max-width: 300px;
    height: auto;
    border-radius: 50%;
    border: 2px solid #646464;
  }
.about-container2 {
    display: flex;
    flex-wrap: wrap;
    background-image: url('../Assets/about.png');
    background-size: cover;
    box-shadow: inset 0 0 0 2000px rgb(121, 202, 199, 0.4);
    align-items: center;
    justify-content: center;
    padding: 20px;
    margin: 1 0px;
  }
  .quote{
    justify-content: center;
    width: 100%;
    max-width: 600px;
    margin: 20px auto;
  }
  
  /* Responsive Design */
  
  @media (max-width: 768px) {
    .about-container {
      flex-direction: column-reverse;
      text-align: center;
    }
  
    .text-container, .image-container {
      flex: 1 1 100%;
    }
  
    .circular-image {
      max-width: 150px;
    }
  }
  