/* Nav Bar styling*/
@font-face {
  font-family: 'Baskervville';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src: url(https://cdn.jsdelivr.net/fontsource/fonts/baskervville@latest/latin-400-normal.woff2) format('woff2'), url(https://cdn.jsdelivr.net/fontsource/fonts/baskervville@latest/latin-400-normal.woff) format('woff');
  unicode-range: U+0000-00FF,U+0131,U+0152-0153,U+02BB-02BC,U+02C6,U+02DA,U+02DC,U+0304,U+0308,U+0329,U+2000-206F,U+2074,U+20AC,U+2122,U+2191,U+2193,U+2212,U+2215,U+FEFF,U+FFFD;
}

.custom-navbar {
  background-color: #8ac2be;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-family: 'Baskervville';
  padding-left: 20px;
  padding-right: 20px;
}
.contact-links{
  padding-left: 10px;
  margin-left: 10px;
  justify-content: center;
}

.custom-navbar .navbar-brand{
  color: #333; 
  text-align: center;
  padding-right: 30px;
}
.custom-navbar .nav-link{
  margin-right: 25px;
  text-decoration: none;
  color: #333; 
  transition: 0.3s ease-in-out;
}
.custom-navbar .nav-link:hover {
  color: #fff;
}
.custom-active{
  color: #fff;
  text-decoration: none;
}
@media (width > 960px) {
  #custom-brand{
    font-size: 2.0rem;
    font-weight: 400;
  }
  .custom-navbar .nav-link {
    text-decoration: none;
    color: #333; 
    font-size: 1.3rem;
    font-weight: 600;
    transition: 0.3s ease-in-out;
  }
  .custom-navbar .nav-link:hover {
    color: #fff;
  }
  .custom-active{
    color: #fff;
    text-decoration: none;
  }
}
@media (width < 960px) {
.custom-navbar .navbar-brand{
  color: #333; 
  text-align: center;
  padding-left: 45px;
  padding-right: 0;
}
}
